import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import i18nBackend from "i18next-http-backend";
import { DEFAULT_LANGUAGE } from "./constants";
import { dev_domain, publish_domain } from "./constants/config_url";

// const getCurrentHost =
//   process.env.NODE_ENV === "development"
//     ? dev_domain : publish_domain;

const getCurrentHost = window.location.origin

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    backend: {
        loadPath: `${getCurrentHost}/i18n/{{lng}}.json`
        // loadPath: `${obs_prefix_url}/i18n/${i18n.language}.json`
    }
  });

export default i18n;